<template>
  <div class="transition-all duration-200" :data-testid="props.dataTestid">
    <div class="flex space-x-1 w-full rounded-lg relative bg-transparent">
      <TsButton
        @click="handleDecrement"
        size="xs"
        :disabled="decrementDisabled"
        append-class="px-2 py-1.5 hover:bg-natural-light-grey disabled:bg-[#F4F4F4]/80 disabled:text-natural-light-grey text-[#666666] bg-natural-light-grey/30 border-0"
        :data-testid="`${props.dataTestid}-decrement`"
      >
        <Icon name="lucide:minus" size="20" />
      </TsButton>
      <input
        type="number"
        inputmode="numeric"
        pattern="[0-9]*"
        class="border-natural-silver-grey read-only:select-none disabled:select-none px-0 rounded-sm min-w-[36px] py-0 text-center text-[#666666] custom-number-input"
        :data-testid="`${props.dataTestid}-input`"
        v-model="count"
        @input="handleInput($event)"
        @blur="handleBlur($event)"
        @change="handleChange($event)"
        @keydown.enter.prevent="handleEnterKey($event)"
        v-bind="$attrs"
        :aria-valuenow="count"
        :min="props.minimumAllowableQuantity"
        :max="props.maximumAllowableQuantity"
      />
      <TsButton
        @click="handleIncrement"
        size="xs"
        :disabled="incrementDisabled"
        append-class="px-2 py-1.5 hover:bg-natural-light-grey disabled:bg-[#F4F4F4]/80 disabled:text-natural-light-grey text-[#666666] bg-natural-light-grey/30 border-0"
        :data-testid="`${props.dataTestid}-increment`"
      >
        <Icon name="lucide:plus" size="20" />
      </TsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";

const count = defineModel<number>({
  default: 1,
});

const emit = defineEmits([
  "increment",
  "decrement",
  "input",
  "blur",
  "enter",
  "change",
]);

type Props = {
  dataTestid?: string;
  minimumAllowableQuantity?: number;
  maximumAllowableQuantity?: number;
  disableDecrement?: boolean;
  disableIncrement?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  minimumAllowableQuantity: 1,
  maximumAllowableQuantity: 1000,
  disableDecrement: false,
  disableIncrement: false,
});

defineOptions({
  inheritAttrs: false,
});

const decrementDisabled = computed(
  () => props.disableDecrement || count.value <= props.minimumAllowableQuantity
);
const incrementDisabled = computed(
  () => props.disableIncrement || count.value >= props.maximumAllowableQuantity
);

function handleInput(e: Event) {
  nextTick(() => {
    const inputValue = (e.target as HTMLInputElement).value;

    count.value = Number(inputValue.replace(/[^0-9]/g, ""));

    if (
      typeof count.value !== "string" &&
      count.value < props.minimumAllowableQuantity
    ) {
      count.value = props.minimumAllowableQuantity;
    } else if (typeof count.value === "string" && !count.value) {
      count.value = null as unknown as number;
    }
    emit("input", e);
  });
}

function resetCount() {
  if (count.value > props.maximumAllowableQuantity) {
    count.value = props.maximumAllowableQuantity;
  }

  if (count.value < props.minimumAllowableQuantity) {
    count.value = props.minimumAllowableQuantity;
  }
}

function handleBlur(e: Event) {
  emit("blur", e);
}

function handleChange(e: Event) {
  resetCount();
  emit("change", e);
}

function handleEnterKey(e: Event) {
  (e.target as HTMLInputElement).blur();
  resetCount();
  emit("enter", e);
}

function handleDecrement() {
  if (count.value > props.minimumAllowableQuantity) {
    count.value--;
    emit("decrement");
  }
}

function handleIncrement() {
  if (count.value < props.maximumAllowableQuantity) {
    count.value++;
    emit("increment");
  }
}

const random_id = ref("");
onMounted(() => {
  random_id.value = useRandomUUID();
});
</script>

<style scoped>
.custom-number-input {
  -webkit-appearance: none; /* Remove default Chrome/Safari styles */
  -moz-appearance: textfield; /* Remove default Firefox styles */
  -webkit-box-shadow: none;

  padding: 0;
  margin: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
  box-sizing: border-box;
  box-shadow: none;
}

.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Remove spin buttons in Chrome/Safari */
  margin: 0;
}

.custom-number-input::-moz-inner-spin-button,
.custom-number-input::-moz-outer-spin-button {
  display: none;
}

.custom-number-input::-moz-focus-outer {
  border: 0; /* Remove focus border in Firefox */
}

.custom-number-input::-ms-clear {
  display: none; /* Remove clear button in Edge */
}
</style>
